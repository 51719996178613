import * as React from 'react'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { vsprintf } from 'sprintf-js'

import {
  QuickBuyStockNotification,
  useProductOptionsTransformer,
} from '@thg-commerce/enterprise-components'
import { SubscriptionPaymentType } from '@thg-commerce/enterprise-components/ProductVariations'
import { QuickBuyModal } from '@thg-commerce/enterprise-components/QuickBuyModal'
import {
  Option,
  VariantFields,
} from '@thg-commerce/enterprise-components/src/transformer/productOptionsTransformer'
import { ProductChoiceTypeMap } from '@thg-commerce/enterprise-config'
import {
  EnterpriseContext,
  i18n,
  useFormattableI18nProperty,
  useLogger,
  useSiteConfig,
  useSiteDefinition,
  useTheme as useCoreTheme,
  useToastPresenter,
  useTransmit,
} from '@thg-commerce/enterprise-core'
import { useBackendEventNormaliser } from '@thg-commerce/enterprise-metrics'
import { SubscriptionChoice } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Product'
import {
  Country,
  Currency,
  Feature,
  FulfilmentMethod,
  MutationAddProductToBasketWithSubscriptionContractArgs,
  Scalars,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { Beacon } from '@thg-commerce/enterprise-network/src/transformers/sponsoredAds/products'
import { getPrice } from '@thg-commerce/enterprise-product-options/src/utils/price'
import { ToastBannerType } from '@thg-commerce/gravity-elements'
import { useTheme } from '@thg-commerce/gravity-patterns/theme'

import { AddProductToBasketWithSubscriptionContract as ADD_TO_BASKET_WITH_CONTRACT_MUTATION } from '../../../graphql/Mutation/AddProductToBasketWithSubscriptionContract.graphql'
import { useHeaderHeight } from '../../EnterpriseContext'
import { BasketContext } from '../BasketContext'
import { useAddToBasket } from '../hooks'
import { InteractionLocation } from '../hooks/useAddToBasket'
import { useBasket, useBasketId } from '../hooks/useBasketId'

import { quickBuyModalDataTrackingCallbacks } from './dataTracking'
import { QuickBuyModal as QUICK_BUY_MODAL_QUERY } from './QuickBuyModal.graphql'
import { getMaxQuantity } from './utils'

const MAX_QUANTITY = 5000

interface QuickBuyModalQueryVariables {
  sku: string
  currency: Currency
  shippingDestination: Country
  tagKeys: string[]
  subscription?: boolean
  subscriptionContractsEnabled?: boolean
  vipPriceEnabled?: boolean
  productChoiceTypeMap?: ProductChoiceTypeMap
  enableNotifyWhenInStock?: boolean
}

interface QuickBuyModalQueryData {
  quickBuyModal: {
    product: {
      sku: string
      title: string
      url: string
      defaultVariant: VariantFields
      images: {
        largeProduct: string
      }[]
      options: Option[]
      tags: string[]
      variants: VariantFields[]
      isSubscription?: boolean
      subscriptionChoices?: {
        upfront: SubscriptionChoice[]
        onDispatch: SubscriptionChoice[]
      }
    }
  }
}

type AddBasketWithContractType = {
  addProductToBasketWithSubscriptionContract: {
    id: string
  }
}

const INVALID_CONTRACT_ID = '15'
export const QuickBuyModalPresenter = () => {
  const logger = useLogger()
  const {
    productTagsKeys,
    showSavingsAmountOnSubscriptionCard,
    enableMobilePDPStickyAddToBasket,
    enableQuickbuyOnlyOnMobile,
    enableVipPrice,
  } = useSiteConfig()

  const { defaultCurrency, defaultLocale } = useSiteDefinition()

  const {
    currency,
    shippingDestination,
    extensionsRef,
    horizonFeatures,
    appConfig,
  } = React.useContext(EnterpriseContext)

  const headerHeight = useHeaderHeight()
  const basketContext = React.useContext(BasketContext)
  const productOptionsTransformer = useProductOptionsTransformer()
  const transmit = useTransmit()
  const normaliseBackendEvent = useBackendEventNormaliser()
  const { basket } = useBasket()
  const theme = useTheme()
  const coreTheme = useCoreTheme()
  const [_, setBasketId] = useBasketId()

  const [open, setOpen] = React.useState(false)
  const [selectedVariant, setSelectedVariant] = React.useState<
    VariantFields | undefined
  >()
  const initialQuantity = React.useRef(1)
  const [showErrorMessage, setShowErrorMessage] = React.useState(false)

  const [addToBaskedWithContracts] = useMutation<
    {
      addProductToBasketWithSubscriptionContract: AddBasketWithContractType
    },
    MutationAddProductToBasketWithSubscriptionContractArgs
  >(ADD_TO_BASKET_WITH_CONTRACT_MUTATION, {
    fetchPolicy: 'no-cache',

    onCompleted: (data) => {
      if (data) {
        setBasketId(
          data.addProductToBasketWithSubscriptionContract
            .addProductToBasketWithSubscriptionContract.id,
        )
      }
    },
    onError: (error) => {
      logger.warn(
        `[AddToBasketWithContracts]: Failed to add to basket ${error.message}`,
      )
      setShowErrorMessage(true)
    },
  })

  const { execute: addToBasket } = useAddToBasket({
    errorHandler: () => setShowErrorMessage(true),
    forceAddToBasket: true,
  })

  const { maxQuantity, quantityInBasket } = getMaxQuantity(
    basket,
    selectedVariant,
  )

  const presentToast = useToastPresenter()
  const [loadData, { data, loading, error }] = useLazyQuery<
    QuickBuyModalQueryData,
    QuickBuyModalQueryVariables
  >(QUICK_BUY_MODAL_QUERY)

  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(
    selectedVariant?.subscriptionPaymentType ===
      SubscriptionPaymentType.ON_DISPATCH &&
      data?.quickBuyModal.product.subscriptionChoices?.upfront.length
      ? 1
      : 0,
  )

  const basketSubscriptionContract = basket?.items?.find(
    (item) => item.product.sku === selectedVariant?.sku,
  )?.subscriptionContract

  const [selectedFrequencyId, setSelectedFrequencyId] = React.useState<string>(
    basketSubscriptionContract?.id ||
      selectedVariant?.subscriptionContracts?.[0]?.id ||
      INVALID_CONTRACT_ID,
  )
  const i18nText = {
    modalTitle: i18n('basket.quickbuy.title'),
    rrpLabel: i18n('general.rrp.text'),
    saveLabel: i18n('general.save.text'),
    addToBasket: i18n('basket.addproduct.text'),
    viewMoreInformation: i18n('basket.productinfolink.text'),
    missingProductMessage: i18n('general.product.quickbuy.missing.text'),
    errorMessage: i18n('basket.addtobasket.error.text'),
    offLabel: i18n('general.off.text'),
  }

  const handleError = React.useCallback(() => {
    presentToast({
      message: i18nText.errorMessage,
      bannerType: ToastBannerType.DANGER,
    })
    setOpen(false)
    return null
  }, [i18nText.errorMessage, presentToast, setOpen])

  React.useEffect(() => {
    if (error) {
      logger.error(
        `QuickBuyModalPresenter: Failed to load data for quick buy modal ${error}`,
      )
      handleError()
    }
  }, [error, handleError, logger])

  React.useEffect(() => {
    if (data && open) {
      if (data.quickBuyModal.product.defaultVariant) {
        setSelectedVariant(data.quickBuyModal.product.defaultVariant)
      } else {
        setSelectedVariant(
          data.quickBuyModal.product.variants.find(
            (variant) => variant.inStock,
          ),
        )
      }
    }
  }, [data, open])

  React.useEffect(() => {
    if (!open) {
      setSelectedVariant(undefined)
    }
  }, [open])

  React.useEffect(() => {
    if (open) {
      const {
        value: [getExtensions],
      } = extensionsRef
      const extensions = getExtensions()
      transmit({
        type: 'page_visit',
        payload: normaliseBackendEvent({
          basket,
          eventData: {
            type: 'page_visit',
            subtype: 'quickbuy_modal_shown',
          },
          experiments: extensions?.experiments,
          requestData: {
            ...(extensions?.LoggerLinkData || {
              start_timestamp: Date.now(),
              duration_ms: 0,
            }),
            url: window.location.href,
          },
        }),
      })
    }
  }, [open, basket, extensionsRef, normaliseBackendEvent, transmit])

  const variantChangedCallback = (variant: VariantFields) => {
    setSelectedVariant(variant)
  }

  const { quickBuyModal } = data || {}

  const productOptions = React.useMemo(
    () =>
      productOptionsTransformer({
        selectedVariant,
        variantChangedCallback,
        options: quickBuyModal?.product.options || [],
        variants: quickBuyModal?.product.variants || [],
      }),
    [selectedVariant, quickBuyModal, productOptionsTransformer],
  )

  const addToBaskedWithContractsItems = (
    currentQuantity: number,
    selectedContractId: string,
  ) => {
    addToBaskedWithContracts({
      variables: {
        basketId: basket?.id,
        sku: selectedVariant?.sku.toString(),
        quantity: currentQuantity,
        contractId: selectedContractId,
        settings: {
          currency: currency as Currency,
          shippingDestination: shippingDestination.code as Country,
        },
      },
    }).then(() => {
      if (!basketContext.presentAddedToBasketModal || !selectedVariant) {
        return
      }

      const currentAddedToBasketModalPresenter =
        basketContext.presentAddedToBasketModal.current

      setOpen(false)
      currentAddedToBasketModalPresenter(
        selectedVariant?.sku.toString(),
        currentQuantity,
      )
    })
  }

  const modalI18nText = {
    closeAriaLabel: i18n('general.modal.close.button.arialabel'),
    closeLabel: i18n('general.modal.close.button.label'),
  }

  const quantitySelectorI18nText = {
    decreaseButtonLabel: i18n('general.quantity.decrease'),
    increaseButtonLabel: i18n('general.quantity.increase'),
    quantityLabel: i18n('general.quantity.text'),
  }

  const basketQuantity18nText = {
    limit: i18n('product.maxquantity.basket.limit.text'),
    singleItemText: i18n('product.maxquantity.basket.singleitem.text'),
    multipleItemsText: i18n('product.maxquantity.basket.multipleitems.text'),
  }

  const subscriptionInformation18nText = {
    productWithContractAlreadyInBasket: i18n(
      'product.inbasketwith.subscription.contracts',
    ),
    productWithoutContractAlreadyInBasket: i18n(
      'product.alreadyaddedinbasket.info.text',
    ),
    oneTimePurchaseDiscountText: useFormattableI18nProperty(
      'product.subscription.onetimepurchase.discount.percentage.text',
    ),
  }

  const maxQuantityBasketItemMessageText = vsprintf(
    quantityInBasket > 1
      ? basketQuantity18nText.multipleItemsText
      : basketQuantity18nText.singleItemText,
    [selectedVariant?.maxPerOrder || MAX_QUANTITY, quantityInBasket],
  )

  const isFromRecommendations = React.useRef<boolean>(false)
  const displayViewMoreInfo = React.useRef<boolean>()
  const onBasketChangeBeaconRef = React.useRef<Beacon>()
  const trackingUrlRef = React.useRef<string>()

  if (!basketContext.presentQuickBuyModal) {
    logger.warn(
      'QuickBuyModalPresenter: Failed to find an initialised BasketContext. Make sure presentQuickBuyModal ref is defined and it wraps QuickBuyModalPresenter.',
    )
    return null
  }

  const hasHorizonSubscriptions =
    horizonFeatures?.includes(Feature.Subscriptions) || false
  const hasHorizonSubscriptionContracts =
    horizonFeatures?.includes(Feature.SubscribeAndSave) || false

  const isSubscriptionPurchaseOptionsActive =
    hasHorizonSubscriptions || hasHorizonSubscriptionContracts

  basketContext.presentQuickBuyModal.current = (
    sku: string,
    quantity: number,
    fromRecommendations: boolean = false,
    onBasketChangeBeacon?: Beacon,
    trackingUrl?: string,
    enableMobilePDPStickyAddToBasket?: boolean,
  ) => {
    initialQuantity.current = quantity

    isFromRecommendations.current = fromRecommendations
    onBasketChangeBeaconRef.current = onBasketChangeBeacon
    trackingUrlRef.current = trackingUrl
    displayViewMoreInfo.current = enableMobilePDPStickyAddToBasket

    loadData({
      variables: {
        subscriptionContractsEnabled: hasHorizonSubscriptionContracts,
        sku: sku as Scalars['SKU'],
        currency: currency as Currency,
        shippingDestination: shippingDestination.code as Country,
        tagKeys: productTagsKeys || [],
        vipPriceEnabled: enableVipPrice,
        productChoiceTypeMap: appConfig.productChoiceTypeMap,
        enableNotifyWhenInStock:
          (enableMobilePDPStickyAddToBasket && enableQuickbuyOnlyOnMobile) ||
          false,
        subscription: hasHorizonSubscriptions,
      },
    })
    setOpen(true)
  }

  if (!open) {
    return null
  }

  if (
    !loading &&
    !data?.quickBuyModal.product.variants.find((variant) => variant.inStock)
  ) {
    logger.error('QuickBuyModalPresenter: Failed to pick a variant to render')
    return handleError()
  }

  const addToBasketCallback = (
    _: { [option: string]: string },
    quantity: number,
  ) => {
    if (!basketContext.presentAddedToBasketModal) {
      return
    }

    if (!selectedVariant) {
      return
    }

    const currentAddedToBasketModalPresenter =
      basketContext.presentAddedToBasketModal.current
    basketContext.presentAddedToBasketModal.current = (
      sku: string,
      quantity: number,
    ) => {
      if (!basketContext.presentAddedToBasketModal) {
        return
      }

      setOpen(false)
      currentAddedToBasketModalPresenter(sku, quantity)

      basketContext.presentAddedToBasketModal.current = currentAddedToBasketModalPresenter
    }

    addToBasket(
      [{ quantity, sku: selectedVariant.sku.toString() }],
      {
        fromRecommendations: isFromRecommendations.current,
        location: InteractionLocation.PRODUCT_LIST,
      },
      FulfilmentMethod.HomeDelivery,
      undefined,
      onBasketChangeBeaconRef.current,
      trackingUrlRef.current,
    )
    quickBuyModalDataTrackingCallbacks.modalAddToBasket()
  }

  const productHasVariantsWithContracts =
    data?.quickBuyModal.product.variants.some(
      (variant) => variant.subscriptionContracts?.length,
    ) || false

  const hasContractsOrIsSubscription =
    selectedVariant?.isSubscription || productHasVariantsWithContracts

  const subscriptionChoices = data?.quickBuyModal.product.subscriptionChoices

  const hasSubscriptionWithChoices =
    hasHorizonSubscriptions &&
    !!(
      subscriptionChoices?.onDispatch?.length ||
      subscriptionChoices?.upfront.length
    )

  const isEmptySubscriptionBasket =
    hasHorizonSubscriptionContracts &&
    selectedVariant &&
    basket?.items?.length === 0

  const productInBasketWithSubscriptionContract =
    !!basketSubscriptionContract && activeTabIndex === 0

  const productInBasketWithoutSubscriptionContract =
    basketSubscriptionContract === null &&
    activeTabIndex === 1 &&
    !isEmptySubscriptionBasket

  const quickBuyModalTheme = theme.patterns.quickBuyModal

  const subscriptionInfoMessageText =
    (productInBasketWithSubscriptionContract
      ? subscriptionInformation18nText.productWithContractAlreadyInBasket
      : subscriptionInformation18nText?.productWithoutContractAlreadyInBasket) ||
    ''

  const selectedContractInitialDiscountPercentage = selectedVariant?.subscriptionContracts?.find(
    (contract) => contract.id === selectedFrequencyId,
  )?.initialDiscountPercentage

  const selectedContractUpsellMessage = selectedVariant?.subscriptionContracts?.find(
    (contract) => contract.id === selectedFrequencyId,
  )?.upsellMessage

  const getSelectedContractDiscountMessage = (
    activeTabIndex: number,
    initialDiscountPercentage: number,
    upsellMessage: string,
  ) => {
    if (activeTabIndex === 0 && initialDiscountPercentage) {
      return subscriptionInformation18nText.oneTimePurchaseDiscountText(
        `${initialDiscountPercentage}%`,
      )
    }
    if (activeTabIndex === 1) {
      return upsellMessage
    }
    return null
  }

  const subscriptionContractDiscountMessage = hasHorizonSubscriptions
    ? getSelectedContractDiscountMessage(
        activeTabIndex,
        selectedContractInitialDiscountPercentage || 0,
        selectedContractUpsellMessage,
      )
    : null

  return (
    <QuickBuyModal
      loading={loading}
      rendering={{
        open,
        onClose: () => {
          setOpen(false)
          quickBuyModalDataTrackingCallbacks.modalClose()
        },
      }}
      setOpen={setOpen}
      displayViewMoreInfo={Boolean(displayViewMoreInfo)}
      i18nText={{
        ...i18nText,
        subscriptionInfoMessageText,
        subscriptionContractDiscountMessage,
        maxQuantityBasketItemMessageText,
        failedToAddToBasketError: showErrorMessage
          ? i18nText.errorMessage
          : undefined,
        availabilityMessage: selectedVariant?.availabilityMessage,
      }}
      modal={{
        i18nText: modalI18nText,
        showHeader: true,
        stickyHeader: true,
        headerOffset: headerHeight,
        gridColSpan: [12, 8, 8, 6],
        'data-testid': 'quick-buy-modal',
        animation: theme.patterns.quickBuyModal.animation,
      }}
      productOptions={productOptions}
      product={
        (data &&
          selectedVariant && {
            sku: data.quickBuyModal.product.sku,
            image: {
              urls: {
                largeProduct: selectedVariant.images[0].largeProduct || '',
              },
              alt: selectedVariant.title,
            },
            title: selectedVariant.title,
            tags: data.quickBuyModal.product.tags,
            inStock: selectedVariant.inStock,
            notifyWhenInStockEnabled:
              selectedVariant?.notifyWhenInStockEnabled || false,
            subscriptionData: {
              productInBasketWithSubscriptionContract,
              productInBasketWithoutSubscriptionContract,
              selectedFrequencyId,
              activeTabIndex,
              enableSubscriptionContracts: hasHorizonSubscriptionContracts,
              subscriptionPurchaseOptions: {
                setActiveTabIndex,
                activeTabIndex,
                selectedFrequencyId,
                setSelectedFrequencyId,
                showSavingsAmountOnSubscriptionCard:
                  showSavingsAmountOnSubscriptionCard || false,
                isSubscription: selectedVariant.isSubscription || false,
                subscribeOptions: subscriptionChoices,
                subscriptionPaymentType:
                  selectedVariant.subscriptionPaymentType,
                subscriptionContracts:
                  selectedVariant.subscriptionContracts || [],
                isQuickBuy: true,
                purchaseOptionsStyle:
                  coreTheme.widget.productList.purchaseOptions,
              },
              displaySubscriptionChoicesOnly: hasSubscriptionWithChoices,
              displaySubscription:
                hasContractsOrIsSubscription &&
                isSubscriptionPurchaseOptionsActive,
            },
          }) ||
        undefined
      }
      pricing={
        selectedVariant &&
        getPrice(selectedVariant, defaultCurrency, defaultLocale)
      }
      links={
        (data && {
          productUrl: data.quickBuyModal.product.url,
        }) ||
        undefined
      }
      callbacks={{
        addedToBasketClicked: addToBasketCallback,
        viewMoreInformationClicked: () => {
          data &&
            quickBuyModalDataTrackingCallbacks.modalInformation(
              data.quickBuyModal.product.sku,
            )
        },
        addToBasketWithContractsCallback: addToBaskedWithContractsItems,
      }}
      quantitySelector={{
        i18nText: quantitySelectorI18nText,
        maxValue: maxQuantity,
        quantity: initialQuantity.current,
      }}
      quickBuyModalTheme={quickBuyModalTheme}
      coreTheme={coreTheme}
      {...(enableMobilePDPStickyAddToBasket &&
        enableQuickbuyOnlyOnMobile && {
          inStockComponent: (
            <QuickBuyStockNotification
              selectedVariant={selectedVariant}
              url={data?.quickBuyModal.product.url || ''}
              styleOverride={{
                margin: { top: 0, bottom: 2 },
              }}
            />
          ),
        })}
    />
  )
}
